import Container from "../Main/BlockElement/Container/Container";
import Section from "../Main/BlockElement/Section/Section";
import Image from "../Main/MediaElement/Image/Image";
import Heading from "../Main/TextElement/Heading/Heading";
import Paragraph from "../Main/TextElement/Paragraph/Paragraph";
import background from '../../assets/contact/background.png';

export default function ContactHeader() {

    return (
        <Section className="w-full flex justify-start items-center">
            <Image src={background} alt="Contact Background" className="w-full object-cover" />
            <Container className="absolute flex w-full md:w-2/3 md:h-1/5 items-start justify-start">
                <Container className="flex flex-col items-stretch justify-center w-full ml-[3dvw]">
                    <Heading type="h1" items={[]} htmlFor={""} className="font-playfair text-md md:text-4xl text-left font-medium pb-2">KONTAKT</Heading>
                    <Paragraph items={[]} htmlFor={""} className="font-playfair text-sm md:text-xl text-left md:mt-5 font-light">
                        Masz dodatkowe pytania lub wątpliwości? Skontaktuj się z nami za pośrednictwem formularza kontaktowego lub wskazanego numeru telefonu.
                    </Paragraph>
                </Container>
            </Container>
        </Section>
    );
}