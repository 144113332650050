import BlogHeader from "../../components/BlogHeader/BlogHeader";
import Card from "../../components/Card/Card";
import Container from "../../components/Main/BlockElement/Container/Container";
import Section from "../../components/Main/BlockElement/Section/Section";
import Page from "../../components/Main/StructureElement/Page/Page";
import { useSearch } from "../../hooks/useSearch";

export default function Blog() {
  const { phrase, setPhrase, blogs, search } = useSearch();

  const cards = (
      <Container className="flex flex-wrap justify-center">
        {blogs?.map((el) => (
          <Container className="md:w-1/3 px-10 pt-10">
            <Card {...el} />
          </Container>
        ))}
      </Container>
  );

  return (
      <Page className="md:pt-[100px]">
          <BlogHeader phrase={phrase} setPhrase={setPhrase} search={search} />
          <Section className="px-10 pb-10">{cards}</Section>
          <Section className="px-10 pb-10">{cards}</Section>
      </Page>
  );
}