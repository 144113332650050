import Container from "../../components/Main/BlockElement/Container/Container";
import Section from "../../components/Main/BlockElement/Section/Section";
import Image from "../../components/Main/MediaElement/Image/Image";
import Page from "../../components/Main/StructureElement/Page/Page";
import Heading from "../../components/Main/TextElement/Heading/Heading";
import Paragraph from "../../components/Main/TextElement/Paragraph/Paragraph";
import JoinUs from "../../components/JoinUs/JoinUs";
import { Button } from "../../components/Main/FormElement/Button";
import ContactHeader from "../../components/ContactHeader/ContactHeader";

export default function Contact() {

    return (
        <Page className="pt-[100px] w-full bg-white">
            <ContactHeader />
            <form className="w-1/4 flex flex-col gap-3 items-start bg-sp-light-pink ml-[5dvw] mt-[50px] p-5">
                <h3 className="font-playfair text-md md:text-3xl text-left font-light pb-2">Formularz Kontaktowy</h3>
                
                <label className="font-playfair text-md md:text-xl text-left font-light pb-2">Nazwa firmy</label>
                <input type='text' className="w-full p-2"/>
                
                <label className="font-playfair text-md md:text-xl text-left font-light pb-2">E-mail</label>
                <input type='text' className="w-full p-2"/>

                <label className="font-playfair text-md md:text-xl text-left font-light pb-2">Numer telefonu</label>
                <input type='text' className="w-full p-2"/>

                <label className="font-playfair text-md md:text-xl text-left font-light pb-2">Treść wiadomości</label>
                <textarea className="w-full p-5"></textarea>

                <Container className="w-full flex justify-end">
                    <Button className={`bg-sp-sweet-pink text-white hover:bg-opacity-90 border-none py-3`}>Wyślij</Button>
                </Container>
            </form>

            <JoinUs
                title="DOŁĄCZ DO ŚWIATA SWEET PROFESSIONAL"
                content="Zarejestruj swój salon i uzyskaj dostęp do Akademii Sweet Professional z naszymi autorskimi kursami i możliwością zakupu produktów."
                primaryColor="sp-sweet-pink"
                secondaryColor="sp-light-pink"
            />
        </Page>
    );
}