import { useCallback, useEffect, useState } from "react";
import { useApi } from "./useApi";
import { ICard } from "../types/ICard";

export const useSearch = () => {
    const [blogs, setBlogs] = useState<ICard[]>();
    const [phrase, setPhrase] = useState<string>('');
    const { api } = useApi();

    const search = useCallback(async () => {
        const formattedPhrase = phrase.trim().replace(/\s+/g, '-').toLowerCase();
        const data = await api.get<ICard[]>(`/api/blog/${formattedPhrase}`);
        setBlogs(data);
    }, [api, phrase]);

    useEffect(() => {
        search();
    }, [search]);

    return { phrase, setPhrase, blogs, setBlogs, search };
};